.sectionContainer{
 background-image: url('./../../images/Section2/section.jpg');
 background-repeat: no-repeat;
 background-size: cover;
 width: 100%;
 height: 500px;
 opacity: .9;
}
.sectionContainer p{
    font-family: 'Nerko One', cursive;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 69px;
    padding: 0px 267px;
    font-weight: 700;
    color: white;
}
.opp p{
    font-family: 'Nerko One', cursive ;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 69px;
    padding:0px 143px;
    font-weight: 700;
    color: white;
}
.opp{
    position: absolute;
    width: 100%;
    height: 500px;
    background: #22242591;
}

@media (min-width:300px) and (max-width:990px) {
    .opp p {
        font-size: 55px;
        padding: 0px 20px;
        font-weight: 600;
        font-family: "Gill Sans", sans-serif;
    }
}