.allOfCheckOut {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    padding: 50px 0px;
}

.contentOfCheckout h1 {
    font-size: 41px;
    font-family: 'Nerko One', cursive ;
    padding-bottom: 60px;
    color: var(--secondary-color);
}
.contentOfCheckout{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkoutbtn {
    background: white;
    padding: 8px 24px;
    font-size: 18px;
    position: relative;
    border-radius: 10px;
    font-weight: 600;
    color: var(--secondary-color);
    border: 3px solid var(--secondary-color);
    width: max-content;
    cursor: pointer;
    transition: 0.2;
}
.checkoutbtn:hover{
    background: var(--dark-color);
    color: var(--secondary-color);
    transform: scale(1.1);
    border: none;
}

.btnofcheckout {
    display: flex;
    align-items: center;
    justify-content: center;
}

.TotalPrice {
    padding: 59px 0px 20px;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    font-family: 'Nerko One', cursive ;
    color: var(--secondary-color);
}
.TotalPrices{
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    font-family: 'Nerko One', cursive ;
    color: var(--secondary-color);
    padding-bottom: 10px;
}
.allthesubone{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.allProductInCheckout {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    gap: 120px;
}
.allProductInCheckoutPoint{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
}


.checkoutinputContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 42%;
}

.checkout input {
    width: 100%;
}
.doggif{
    width: 626px;
}
.checkoutInPut{
    display: flex;
    flex-direction: column;
    max-width:100%;
    border-radius: 10px;
    /* align-items: center; */
}
.checkoutInPut input{
    padding: 15px;
    padding: 15px 10px;
    width: 349px;
    margin: 22px 0px;
    border-radius: 5px;
    border: 1px solid #222425;
    background: #fbffff;
}
.error-message {
    position: relative;
    color: red;
    /* margin-top: 5px; */
    font-size: 14px;
    /* padding-bottom: 11px; */
    top: -15px;
    left: 7px;
  }

  .input-container {
    position: relative;
  }
  
  .address-input {
    padding-right: 100px; 
    padding: 15px;
    padding: 15px 10px;
    width: 349px;
    margin: 22px 0px;
    border-radius: 5px;
    border: 1px solid #222425;
    background: #fbffff;
    height: 200px;
  }
  
  .restore-button,
  .save-button {
    position: absolute;
    top: 176px;
    right: 4px;
    /* height: 100%; */
    padding: 10px;
    background-color: #ccc;
    border: none;
    cursor: pointer;
    border-radius: 9px;
  }
  
  .save-button {
    background-color: var(--dark-color); 
    color: #fff; 
  }
  .divOffSelectDistrict{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
  }
  .selectDistrict{
    font-size: 20px;
    font-weight: 600;
    font-family: 'Nerko One', cursive;
    color: var(--secondary-color);
}

.SelectDistrictDropDown{
    border: 1px solid var(--secondary-color);
    font-size: 16px;
    width: 180px;
    font-weight: 600;
    font-family: 'Nerko One', cursive;
    color: var(--secondary-color);
    border-radius: 5px;
    padding: 10px;
}
.delvery_fees{
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Nerko One', cursive;
    color: #ed6fa3;
}



@media (min-width:300px) and (max-width:500px) {
    .allProductInCheckout {
        grid-template-columns: repeat(1, 1fr);
        place-items: center;
    }
    .doggif {
        width: 326px;
    }
    .contentOfCheckout h1 {
        font-size: 31px;
    }
}

@media (min-width:501px) and (max-width:800px) {
    .allProductInCheckout {
        grid-template-columns: repeat(2, 1fr);
        place-items: center;
    }
    .doggif {
        width: 326px;
    }
    .contentOfCheckout h1 {
        font-size: 31px;
    }
}

@media (min-width:801px) and (max-width:1200px) {
    .allProductInCheckout {
        grid-template-columns: repeat(3, 1fr);
        place-items: center;
    }
    .doggif {
        width: 426px;
    }
    .contentOfCheckout h1 {
        font-size: 31px;
    }
}

@media (min-width:300px) and (max-width:450px) {
    .TotalPrices , .TotalPrice ,.contentOfCheckout h1 { 
    font-family: "Gill Sans", sans-serif;
    }
}