.termcontainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}
.pTerms{
    padding:40px;
}
.pTerms strong{
    font-size: 35px;
    font-family: 'Nerko One', cursive !important;
}
.pTerms p {
    text-align: justify;
    font-size: 20px;
    padding: 10px 0px;
}
.welcome{
    font-size: 35px !important;
    font-weight: 600;
    font-family: 'Nerko One', cursive !important;
    color: var(--secondary-color);
}
.imgTerm img{
    width: 666px;
}

@media (min-width:300px) and (max-width:500px) {
    .imgTerm img{
        width: 400px;
    }
}