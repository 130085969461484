.ContainerOfAllSeller {
    padding: 40px 0px 0px;
}

.bestSellerItems {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 20px;
    gap: 57px;
}

.bestSellerItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 60px;
}
.allContainerOfBestSeller{
    padding: 50px;
}

.btnSeller {
    display: flex;
    justify-items: center;
    justify-content: center;
    padding: 46px 0px;
}

/* .btnSeller button {
    padding: 12px 24px;
    font-size: 18px;
    top: 34px;
    border-radius: 10px;
    font-weight: 600;
    background: var(--dark-color);
    color: var(--secondary-color);
    border: none;
    transition: 0.2s;
}

.btnSeller button:hover {
        background-color: #ffffff;
        color: var(--secondary-color);
        border: 3px solid var(--secondary-color);
        transform: scale(1.1);
} */
@keyframes cuteButtonAnimation {
    0% {
        transform: scale(1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    50% {
        transform: scale(1.05);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
}

.btnSeller button {
    padding: 12px 24px;
    font-size: 18px;
    margin-top: 10px;
    border-radius: 10px;
    font-weight: 600;
    background: var(--dark-color);
    color: var(--secondary-color);
    background: linear-gradient(90deg, #ed6fa3c7, #f5a6c596);;
    border: none;
    cursor: url('./../../images/Header/pawprint.png'), auto;
    animation: cuteButtonAnimation 1s infinite;
}

.btnSeller button:hover {
    animation: none; /* Disable the animation on hover */
    transform: scale(1.1);
    color: white;
    background: var(--secondary-color); /* Change this to a lighter color for a cute effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);

}


.imgseller {
    cursor: pointer;
    width: 200px;
    height: 220px;
    padding: 27px;
    background: #ffffff;
    border-radius: 10px;
}

.ContainerOfdescriptionAndPrice {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    cursor: pointer;
    padding: 0px
}

.ContainerOfdescriptionAndPrice h2 {
    padding-bottom: 10px;
    font-weight: 300;
    padding-top: 16px;
    font-size: 21px;
    text-align: center;
    font-family: 'Nerko One', cursive ;
    color: var(--secondary-color);
}

.ContainerOfdescriptionAndPrice p {
    font-weight: 600;
    font-size: 20px;
    font-family: 'Nerko One', cursive ;
    color: var(--secondary-color);
}

.reverse3 {
    position: absolute;
    width: 104px;
    right: -13px;
    /* bottom: 1px; */
}

.divCacher {
    position: relative;
    overflow: hidden;
    background: #ffffff;
    border-radius: 15px;
    width: 200px;
    height: 220px;
    box-shadow: 1px 2px 11px #cdc9cb;
  }
  
  /* .imgseller {
    width: 75%;
  }
   */
  .overlay {
    position: absolute;
    top: 0;
    left: 57%;
    width: 40%;
    height: 98%;
    background:transparent;
    display: flex;
    align-items: end;
    flex-direction: column;
    opacity: 0;
    border-radius: 10px;
    padding: 20px 5px;
    cursor: pointer;
    transition: opacity 0.4s ease-in-out;
  }
  .icon12{
    width: 16px;
    height: 16px;
    padding:10px 10px;
    margin-bottom: 5px;
    background-color: var(--secondary-color);
    border-radius: 50%;
    color: white;
    cursor: pointer;
  }
  
  .divCacher:hover .overlay {
    opacity: 1;
    transform: translateX(-5px);
  }
  .priceContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  
  .regularPricee{
    margin: 0;
    text-decoration: line-through;
    color: #aaa6a6 !important;
    font-size: 15px !important;
    text-decoration-color:#838282;
  }
  
  .offerPrice {
    background: #ffb2d2db;
    padding: 1px 5px;
    color: var(--secondary-color) !important;
    border-radius: 11px;
    margin-top: 5px;
}

@media (min-width:300px) and (max-width:500px) {
    .bestSellerItems {
        grid-template-columns: repeat(1, 1fr);
        place-items: center;
        /* padding: 44px 87px; */
    }
    .ContainerOfdescriptionAndPrice h2{
        font-family: "Gill Sans", sans-serif;
        font-weight: 600;
    }
    .ContainerOfdescriptionAndPrice p{
        font-family: "Gill Sans", sans-serif;

    }
    .reverse3 {
        position: absolute;
        width: 62px;
        right: 9px;
        bottom: -115px;
    }
    .btnSeller{
        padding: 0px;
    }
}

@media (min-width:501px) and (max-width:800px) {
    .bestSellerItems {
        place-items: center;
        grid-template-columns: repeat(2, 1fr);
        /* padding: 44px 87px; */
    }
}

@media (min-width:801px) and (max-width:1024px) {
    .bestSellerItems {
        grid-template-columns: repeat(3, 1fr);
        /* padding: 44px 87px; */
    }
}