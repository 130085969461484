.CategoriesTitle {
    font-family: 'Nerko One', cursive ;
    text-align: center;
    color: var(--secondary-color);
    letter-spacing: 2px;
    width: 30%;
}

.titleOfNAmeCAtegories {
    padding-top: 10px;
    font-family: 'Nerko One', cursive ;
    text-align: center;
    font-size: 44px;
    color: #08343f;
    letter-spacing: 2px;
}

.categoriesContainer {
    padding: 40px 10px 0px;
}

.categoriesContent {
    display: flex;
    position: relative;
    justify-content: space-around;
    top: -80px;
}

.categoriesContent>div {
    position: relative;
    /* margin: 20px;  */
}

.categoriesContent img.vector {
    position: absolute;
    top: 135px;
    left: 0;
    width: 100%;
    height: 41%;
}

.titleOfNAmeCAtegories1 {
    /* padding-top: 10px; */
    font-family: 'Nerko One', cursive ;
    text-align: center;
    font-size: 44px;
    color: #08343f;
    letter-spacing: 2px;
}

.btnCategories {
    display: flex;
    justify-items: center;
    justify-content: center;
    /* padding: 46px 0px; */
}

.dogImage {
    width: 448px;
}

@keyframes cuteButtonAnimation {
    0% {
        transform: scale(1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    50% {
        transform: scale(1.05);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
}

.btnCategories button {
    padding: 12px 24px;
    font-size: 18px;
    margin-top: 10px;
    border-radius: 10px;
    font-weight: 600;
    background: var(--dark-color);
    color: var(--secondary-color);
    background: linear-gradient(90deg, #ed6fa3c7, #f5a6c596);
    ;
    border: none;
    cursor: pointer;
    animation: cuteButtonAnimation 1s infinite;
}

.btnCategories button:hover {
    animation: none;
    /* Disable the animation on hover */
    transform: scale(1.1);
    color: white;
    background: var(--secondary-color);
    /* Change this to a lighter color for a cute effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.reverse {
    width: 84px;
    position: relative;
    left: 39px;
    /* top: 242px; */
}

.reverse2 {
    position: absolute;
    width: 76px;
    right: 31px;
    /* top: ; */
}

.divider {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    padding: 0px 111px;
    color: var(--secondary-color);
    align-items: center;
}

.divider-line {
    width: 49%;
    height: 4px;
    background-color: var(--secondary-color);
}

/* .btnCategories button:hover{
    background-color: #ffffff;
    color: var(--secondary-color);
    border: 3px solid var(--secondary-color);
    transform: scale(1.1);
} */

/* @media (min-width:280px) and (max-width:299px)  {
    
    .categoriesContent {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        justify-items: center;
    }

    .animalImg{
        width: 250px;
        height: 380px;
    }
} */

/* .animalImg {
    cursor: url('../../images/Header/paw.png'), auto;
} */

@media (min-width:300px) and (max-width:450px) {
    .categoriesContent {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        justify-items: center;
        /* top: 5px; */


    }

    .animalImg {
        width: 350px;
        height: 380px;
    }

    /* .reverse {} */

    .titleOfNAmeCAtegories {
        font-size: 30px;
        font-family: "Gill Sans", sans-serif;
    }
    .titleOfNAmeCAtegories1{
        font-size: 30px;
        font-family: "Gill Sans", sans-serif;
    }

    .btnCategories button {
        padding: 9px 26px;
        font-size: 16px;
        top: 21px;
    }

    .reverse img {
        width: 60px;
    }

    .CategoriesTitle {
        width: 100%;
        font-family: "Gill Sans", sans-serif;
    }

    .reverse2 {
        display: none;
        position: absolute;
        width: 20px;
        right: 28px;
    }

    .reverse2 img {
        width: 50px;
    }

    .divider {
        padding: 0px 0px;
    }
    .divider-line {
        width: 32%;
        height: 4px;
        background-color: var(--secondary-color);
    }


}

@media (min-width: 412px) and (max-width: 500px) {
    .categoriesContent img.vector {
        top: 133px;
        width: 88%;
        height: 50.5%;
    }

    .categoriesContent {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        justify-items: center;
        /* top: 5px; */

    }

    .divider {
        padding: 0px;
    }

    .reverse {
        /* top: 119px; */
        left: 19px;
    }

    .reverse img {
        width: 40px;
    }

    .animalImg {
        z-index: 1000;
        position: relative;
        height: 78%;
        width: 73.5%;
        left: 52px;
    }

    .CategoriesTitle {
        width: 100%;
    }
}

@media (min-width:360px) and (max-width:360px) {
    .reverse {
        /* top: 119px; */
        left: 19px;
    }

    .reverse img {
        width: 40px;
    }

}

@media (min-width:540px) and (max-width:767px) {

    .categoriesContent {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        justify-items: center;
        top: 5px;

    }

    .animalImg {
        position: relative;
        height: 84%;
        width: 58.5%;
        top: 8px;
        left: 102px;
    }

    .reverse {
        width: 84px;
        position: absolute;
        left: 93px;
        /* top: 158px; */
    }

    .reverse img {
        width: 70px;
    }

    .CategoriesTitle {
        width: 100%;
    }


}

@media (min-width:768px) and (max-width:1000px) {
    .categoriesContent {
        top: 5px;
    }

    .animalImg {
        z-index: 1000;
        position: relative;
        height: 61%;
        width: 64.5%;
        left: 59px;
    }

    .reverse2 {
        position: absolute;
        width: -1px;
        right: 51px;
    }

    /* 
    .reverse {
        width: 27px;
        position: absolute;
        left: 14px;
        top: 125px;
    } */
    .reverse img {
        width: 72px;
    }
}

@media (min-width:1001px) and (max-width:1229px) {
    .reverse {
        width: 84px;
        position: absolute;
        left: 79px;
    }

    .reverse img {
        width: 90px;
    }

    .reverse2 {
        bottom: -93px;
    }
}

@media (min-width:600px) and (max-width:900px) {
    .CategoriesTitle {
        width: 100%;
    }
}