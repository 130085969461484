.allcontainerOfproducts{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:67px 20px 55px;
}
.catPlusPro{
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items:start;
}
.spinnerInCat{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
}
.ProductNotExist{
    font-size: 29px;
    width: 46%;
    font-weight: 600;
    color: var(--secondary-color);
}
@media (min-width:300px) and (max-width:1100px) {
    .catPlusPro {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
        align-items: start;
    }
}

@media (min-width:300px) and (max-width:450px) {
    .spinnerInCat{
        width: 100%;
    }
    .ProductNotExist {
        width: 100%;
        padding: 0px 10px;
    }
}

@media (min-width:451px) and (max-width:1095px){
    .ProductNotExist {
        width: 100%;
        padding: 0px 10px;
        text-align: center;
    }
}