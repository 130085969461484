

.PointsContainer{
    display: flex;
    justify-content: space-around;
    padding: 30px 100px;
}
.PointsInformation h1{
    font-family: 'Nerko One', cursive;
    font-size: 69px;
    color: var(--secondary-color);
    padding-bottom: 20px;
}
.PointsInformation p{
    font-size: 35px;
    width: 90%;
    font-family: 'Nerko One', cursive;

}
.point{
    font-size: 35px;
    width:61% !important;
    font-family: 'Nerko One', cursive ;
    display: inline-block;
    color: var(--dark-color);
}
.PointsInformation{
    padding-top: 36px;
}
.gifImgPoint img{
    width: 597px;
    border-radius: 29px
}
.gifImgPoint{
    padding-top: 50px;
}
.point12{
    display: flex;
}
.hide-arrows{
    display: none;
}
.PointContainerOfdescriptionAndPrice {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:82%;
    justify-content: center;
    padding: 0px;
}
.PointContainerOfdescriptionAndPrice h2 {
    padding-bottom: 10px;
    font-weight: 300;
    padding-top: 16px;
    font-size: 24px;
    text-align: center;
    cursor: pointer;
    font-family: 'Nerko One', cursive ;
    color: var(--secondary-color);
}

.PointContainerOfdescriptionAndPrice p {
    font-weight: 600;
    font-size: 20px;
    font-family: 'Nerko One', cursive ;
    color: var(--secondary-color);
}
.carouselContainer{
    padding: 90px 25px;
}
/* .react-multiple-carousel__arrow {
    position: relative !important;

} */
.LinkFromPoint{
    color: #ed6fa3;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Nerko One', cursive ;
}
.divOfLinkPoint{
    padding: 100px 10px 0px;
}
.carouselContainer h3{
    font-family: 'Nerko One', cursive ;
    font-size: 40px;
    color: var(--secondary-color);
    padding-bottom: 40px;
}
.PointPrice{
    color: #ed6fa3  !important;
}
.numberOfPoint{
    display: inline-block;
    color:#ed6fa3  ;
    font-size: 35px;
    font-weight: 600;
}
.shareYourLink{
    display: inline-block;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Nerko One', cursive ;
    color: black !important;
}

@media (min-width:300px) and (max-width:1024px){
    .PointsContainer{
        flex-direction: column;
        padding: 10px 31px;
    }
}

@media(min-width:280px) and (max-width:390px){
    .gifImgPoint img{
        width: 267px;
    }
}

@media (min-width:300px) and (max-width:450px) {
    .PointsInformation h1,.PointsInformation p,.point,.PointContainerOfdescriptionAndPrice h2,.PointContainerOfdescriptionAndPrice p,.carouselContainer h3{
        font-family: "Gill Sans", sans-serif;
    }
    .gifImgPoint img{
        width: 350px;
    }
    .PointsInformation h1 {
        font-size: 50px;
    }
    .PointsInformation p {
        font-size: 30px;
    }
    .PointContainerOfdescriptionAndPrice {
        width:100%;;
    }
    .point{
        width: 100%;
    }
}