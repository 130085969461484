.allContainerOfSignUp1 {
    display: flex;
    /* height:100%; */
    justify-content: space-around;
    align-items: center;
    padding: 171px 30px;
    flex-wrap: wrap;

}


.signUpheader {
    text-align: center;
    padding-bottom: 14px;
    font-size: 23px;
}

.inputforregistration {
    padding: 15px;
    /* height: 16px; */
    padding: 15px 10px;
    width: 349px;
    margin: 22px 0px;
    /* box-shadow: 1px 2px 5px rgb(0 0 0 / 24%); */
    border-radius: 5px;
    border: 1px solid #222425;
    background: #fbffff;
}

span {
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
}

input:invalid[focused="true"] {
    border: 1px solid red;
}

input:invalid[focused="true"]~span {
    display: block;
    width: 100%;
    padding-bottom: 14px;
}


.allContainerOfSignUp {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 60px 43px;
    flex-wrap: wrap;
}

.allContainerOfRegistration {
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    padding: 86px 32px;
    width: 100%;
}

.allContainerOfRegistration h1 {
    font-family: 'Nerko One', cursive;
    font-size: 59px;
    width: 97%;
    color: var(--secondary-color);
}

.craeteAccount {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 110px;
    width: 50%;
}

.craeteAccount1 {
    font-size: 35px;
    font-weight: 600;
    padding-bottom: 12px;
    color: var(--secondary-color);
    font-family: 'Nerko One', cursive ;
}

.btnregistartion {
    border-radius: 10px;
    background: #f6a7c6;
    width: 349px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    height: 48px;
    color: var(--secondary-color);
}

.petsWelcome {
    width: 80%
}

.pLogin1 {
    width: 100%;
    padding-top: 15px;
    font-size: 20px;
}

.overlay1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #222425;
    display: flex;
    align-items: end;
    flex-direction: column;
    opacity: 0;

    cursor: pointer;

}
.checkbox{
    display: flex;
    justify-content: space-around;
    padding: 10px;
}
.labelaterms{
    padding-left: 10px;
    cursor: pointer;
  }
.Toastify__toast--success {
    background-color: #4caf50;
    color: #fff;
  }
  .Toastify__toast--error {
    background-color: #f44336;
    color: #fff;
  }
  

@media (min-width:451px) and (max-width:1117px) {
    .allContainerOfRegistration {
        flex-direction: column;
        align-items: flex-start;
    }

    .allContainerOfRegistration h1 {
        width: 100%;
        padding: 56px
    }

    .craeteAccount {
        padding-left: 10px;

    }
}

@media (min-width:300px) and (max-width:450px) {
    .allContainerOfRegistration {
        flex-direction: column;
        align-items: flex-start;
        padding: 32px 7px;
    }

    .allContainerOfRegistration h1 {
        width: 100%;
        padding: 5px;
        font-size: 35px;
        font-weight: 600;
    
    }

    .craeteAccount {
        padding-left: 0px;
    }

    .gif {
        width: 340px;
    }

    /* .craeteAccount p {
        width: 400px;
    } */
    .overlay1 {
        height: 293%;
    }
    .labelaterms {
        font-size: 16px;
    }
}


@media (min-width:300px) and (max-width:500px) {
    .inputforregistration {
        width: 342px;
    }
    .craeteAccount {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .pLogin1 {
        font-size: 15px;
        text-align: center;
    }
    .btnregistartion {
        width: 342px;}
}

@media (min-width:300px) and (max-width:450px) {
    .allContainerOfRegistration h1{
        font-family: "Gill Sans", sans-serif;
    }
    .craeteAccount1{
        font-family: "Gill Sans", sans-serif;
    }
    .petsWelcome {
        width: 100%;
    }
}