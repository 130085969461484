.allwishlist{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 0px;
}
.allwishlist h1{
    font-size: 50px;
    color: var(--secondary-color);
    font-family: 'Nerko One', cursive;
    padding-bottom: 63px;
}
.ButtonPlusTotal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-top: 66px; */
}
.ButtonPlusTotal p{
    padding: 14px 0px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Nerko One', cursive;
    color: var(--secondary-color);
}

@keyframes cuteButtonAnimation {
    0% {
        transform: scale(1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    50% {
        transform: scale(1.05);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
}

.ButtonPlusTotal button {
    padding: 12px 24px;
    font-size: 18px;
    margin-top: 10px;
    border-radius: 10px;
    font-weight: 600;
    background: var(--dark-color);
    color: var(--secondary-color);
    background: linear-gradient(90deg, #ed6fa3c7, #f5a6c596);;
    border: none;
    cursor: pointer;
    animation: cuteButtonAnimation 1s infinite;
}

.ButtonPlusTotal button:hover {
    animation: none; 
    transform: scale(1.1);
    color: white;
    background: var(--secondary-color); 
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
.Product34{
    display: grid;
    max-width: 1400px;
    grid-template-columns: repeat(4,minmax(200px,1fr));
    gap: 43px;
    padding-bottom: 73px;
}
@media (min-width:300px) and (max-width:600px) {
    .Product34{
        grid-template-columns: repeat(1,minmax(200px,1fr));
    }
    .allwishlist h1 {
        font-size: 27px;
        padding: 0px 8px;
    }

}

@media (min-width:601px) and (max-width:900px) {
    .Product34{
        grid-template-columns: repeat(2,minmax(200px,1fr));
    }
    .allwishlist h1 {
        font-size: 39px;
    }
}

@media (min-width:901px) and (max-width:1100px) {
    .Product34{
        grid-template-columns: repeat(3,minmax(200px,1fr));
    }
    .allwishlist h1 {
        font-size: 39px;
    }
}

@media (min-width:300px) and (max-width:450px)  {
    .home{
        overflow: hidden
    }
    .allwishlist h1{
        font-family: "Gill Sans", sans-serif !important;
        line-height: 1.4;
        padding: 0px 4px;
        text-align: center;
    }
    .ButtonPlusTotal p{
        font-family: "Gill Sans", sans-serif !important;

    }
}