.paidUnpaid{
    background-color: #b5b5b585;
    color: #003440;
    font-size: 15px;
    font-family: 'Nerko One', cursive ;
    padding: 8px;
    font-weight: 600;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}
.innvoiceContainer{
    padding: 30px 0px;
}

.contentOfcart1 {
    background: #e1e1e1;
    padding: 11px 28px;
    border-radius: 10px;
    max-width: 199px;
}
.quan{
    /* width: 8%; */
    text-align: center;
}
.TotalPrice111{
    text-align: center;
    font-weight: 600;
    padding: 20px
}
.contentOfcart12{
    background: #e1e1e1;
    padding: 11px 28px;
    border-radius: 10px;
    max-width: 144px;
}
/* .containerOfInvoice{
    overflow-y: scroll;
    max-height: 400px;
} */
.innvoice{
    padding: 50px 50px;
}

.invoiceTable{
    width: 100%;
    border-collapse: collapse;
}
.invoiceTable th, .invoiceTable td {
    border: 1px solid #ddd; 
    padding: 8px;
    text-align: center;
    width: 20%;
    font-family: 'Nerko One', cursive ;
  }
  .invoiceTable tbody tr {
    border-bottom: 1px solid #ddd;
  }
  .invoiceTable thead tr {
      background-color: #08343f;
      color: white;
  }
  .containerOfInvoice{
    overflow-y: scroll;
    height: 600px;
  }
  .status span{
    display: block;
    color: black;
    font-size: 16px;
    font-weight: 600;

  }
  .totalOfInvoice{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .totalOfInvoice h3{
    background-color: var(--dark-color);
    border-radius: 10px;
    padding: 10px;
    font-family: 'Nerko One', cursive ;

  }
  .label-danger {
    display: block;
    color: black;
    padding: 10px;
    font-size: 15px;
    font-weight: 600;
    border-radius:10px;
    font-family: 'Nerko One', cursive ;
    background-color: #ff3e37b8; /* Light yellow */
  }
  
  .label-outline {
    display: block;
    color: black;
    padding: 10px;
    font-size: 15px;
    font-weight: 600;
    border-radius:10px;
    font-family: 'Nerko One', cursive ;
    background-color: #66cd7b; /* Lime green */
  }
  
 .label-primary{
    display: block;
    color: black;
    padding: 10px;
    font-size: 15px;
    font-weight: 600;
    border-radius:10px;
    font-family: 'Nerko One', cursive ;
    background-color: #84d4ef; /* Deep sky blue */
  }
  .label-info{
    display: block;
    color: black;
    padding: 10px;
    font-size: 15px;
    font-weight: 600;
    border-radius:10px;
    font-family: 'Nerko One', cursive;
    background-color:  #d69ce1;
  }
  .des{
    font-family: 'Nerko One', cursive;
    padding: 10px;
    font-size: 15px;
    font-weight: 600;
  }
  .themainTitle th{
    font-size: 20px;
    padding: 10px;
  }
  /* .ss{
    padding-top:30px ;
  } */

@media (min-width:300px) and (max-width:900px) {
    .ContainerForTitle{
        overflow-x: scroll;
    }
}

@media (min-width:300px) and (max-width:450px) {
  .paidUnpaid , .des h3,  .label-info , .totalOfInvoice h3 ,.invoiceTable th, .invoiceTable td ,.TotalPrice111{ 
    font-family: "Gill Sans", sans-serif;
  }
  .innvoice {
    padding: 40px 35px;
}

}