
:root {
  --main-color: #9ec37d;
  --dark-color:#F6A7C6;
  --secondary-color:#003440;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: 'Poppins', sans-serif;
}

*{
  font-family: 'Poppins', sans-serif;
  outline: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  border: none;
}
a{
  text-decoration: none;
  color: white;
}
ul{
  list-style-type: none;
}

.html{
  overflow-x: hidden;
}