.Productitems{
    display: grid;
    max-width: 1400px;
    grid-template-columns: repeat(3,225px);
    gap: 20px
}
.otherPageStyle{
    display: grid;
    max-width: 1400px;
    grid-template-columns: repeat(5,230px);
    gap: 20px
}
.ContainerOfcat{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.btnproduct{
    display: flex;
    justify-items: center;
    justify-content: center;
    padding-top: 50px;
    
}
.btnproduct button{
    background: var(--dark-color);
    padding: 8px 24px;
    font-size: 18px;
    /* position: relative; */
    border-radius: 10px;
    font-weight: 600;
    color: var(--secondary-color);
    /* border: 3px solid var(--secondary-color);*/
    transition: 0.2s ; 
}
.btnproduct button:hover{
    background: white;
    font-weight: 600;
    color: var(--secondary-color);
    border: 3px solid var(--secondary-color);

}
.outOfStock{
  background-color: black;
  opacity: 0.1;
  cursor: not-allowed;

}
.stock_status{
    position: absolute;
    /* display: block; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    align-content: center;
    width: 100%;
    font-weight: 800;
    color: black;
    cursor: not-allowed;
    font-size: 28px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.overlayOutOfStock{
    display: none;
}
.priceContainer span{
    display: block;
}
/* Example styles */
.priceContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .priceContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  
  .regularPricee{
    margin: 0;
    text-decoration: line-through;
    color: #aaa6a6 !important;
    font-size: 15px !important;
    text-decoration-color:#838282;
  }
  
  .offerPrice {
    background: #ffb2d2db;
    padding: 1px 5px;
    color: var(--secondary-color) !important;
    border-radius: 11px;
    margin-top: 5px;

  }
@media (min-width:300px) and (max-width:600px) {
    .Productitems {
        display: grid;
        max-width:100%;
        grid-template-columns: repeat(1,minmax(200px,1fr));
        gap: 23px;
        place-items: center;
    }
    .otherPageStyle{
        display: grid;
        max-width:100%;
        grid-template-columns: repeat(1,minmax(200px,1fr));
        gap: 23px;
        place-items: center;
    }
    .allcontainerOfproducts{
        padding:  10px;
    }
    .ContainerOfcat {
        width: 100%;
    }
}

@media (min-width:601px) and (max-width:900px) {
    .Productitems {
        display: grid;
        max-width:100%;
        grid-template-columns: repeat(2,minmax(200px,1fr));
        gap: 23px;
        place-items: center;
    }
    .otherPageStyle{
        display: grid;
        max-width:100%;
        grid-template-columns: repeat(2,minmax(200px,1fr));
        gap: 23px;
        place-items: center;
    }
    .allcontainerOfproducts{
        padding:  10px;
    }
    .ContainerOfcat {
        width: 100%;
    }
}

@media (min-width:901px) and (max-width:1100px) {
    .Productitems {
        display: grid;
        max-width:100%;
        grid-template-columns: repeat(3,minmax(200px,1fr));
        gap: 23px;
        place-items: center;
    }
    .otherPageStyle{
        display: grid;
        max-width:100%;
        grid-template-columns: repeat(3,minmax(200px,1fr));
        gap: 23px;
        place-items: center;
    }
    .allcontainerOfproducts{
        padding:  10px;
    }
    .ContainerOfcat {
        width: 100%;
    }
}


@media (min-width:1101px) and (max-width:1200px) {
    .Productitems {
        display: grid;
        max-width:100%;
        grid-template-columns: repeat(3,minmax(200px,1fr));
        gap: 23px;
        place-items: center;
    }
    .otherPageStyle{
        display: grid;
        max-width:100%;
        grid-template-columns: repeat(3,minmax(200px,1fr));
        gap: 23px;
        place-items: center;
    }
}

