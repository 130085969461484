.containerofcontact{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 10px;
    flex-wrap: wrap;
}
.contactTitle{
    font-size: 41px;
    font-family: 'Nerko One', cursive ;
    /* padding-bottom: 21px; */
    color: var(--secondary-color);
}
/* .contactbtn{
    background: var(--dark-color);
    padding: 8px 24px;
    font-size: 18px;
    position: relative;
    border-radius: 10px;
    font-weight: 600;
    width: -webkit-fill-available;
    color: var(--secondary-color);
    cursor: pointer;
}
.contactbtn:hover{
    background: white;
    padding: 15px 24px;
    font-size: 18px;
    position: relative;
    border-radius: 10px;
    font-weight: 600;
    width: 349px;
    color: var(--secondary-color);
    cursor: pointer;
    border: 3px solid var(--secondary-color);
} */

@keyframes cuteButtonAnimation {
    0% {
        transform: scale(1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    50% {
        transform: scale(1.05);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
}

.contactbtn{
    padding: 12px 24px;
    font-size: 18px;
    width:340px;
    margin-top: 10px;
    border-radius: 10px;
    font-weight: 600;
    background: var(--dark-color);
    color: var(--secondary-color);
    background: linear-gradient(90deg, #ed6fa3c7, #f5a6c596);;
    border: none;
    cursor: url('./../../images/Header/pawprint.png'), auto;
    animation: cuteButtonAnimation 1s infinite;
}

.contactbtn:hover {
    animation: none; /* Disable the animation on hover */
    /* transform: scale(1.1); */
    color: white;
    background: var(--secondary-color); /* Change this to a lighter color for a cute effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);

}
.ContactUsform{
padding: 15px;
height: 16px;
padding: 22px 10px;
width: 349px;
margin: 22px 0px;
/* box-shadow: 1px 2px 5px rgb(0 0 0 / 24%); */
border-radius: 5px;
border: 1px solid #222425;
background: #fbffff;
}
.textareaOfcontact{
    width: 100%;
    height: 140px;
    padding: 18px 10px;
    border: 1px solid #222425;
    border-radius: 5px;
    margin: 10px 0px;
}
.videoContact img{
    width: 646px;
    height: 642px
}
.videoContact{
    /* padding-left: 148px; */
    position: relative;
    top: 47px;
}
.contentOfTextarea{
    width: 100%;
}
.Toastify__toast--success {
    background-color: #ffffff;
    color: #08343f;
}

@media (min-width:250px) and (max-width:500px) {
    .videoContact img{
        width: 300px;
        height: 300px
    }
    .videoContact{
        padding-left: 0px;
    }
}

@media (min-width:501px) and (max-width:900px) {

    .videoContact img{
        width: 500px;
        height: 500px
    }
    .videoContact{
        padding-left: 0px;
    }
}

@media (min-width:300px) and (max-width:450px) {
    .contactTitle{
        font-family: "Gill Sans", sans-serif;

    }
}