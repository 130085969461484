/* .QuantityOfCard {
    width:64%;
} */
.QuantityBtn{
    display: flex;
    justify-content: space-around;
    border: 2px solid #000000;
    border-radius: 5px;
    margin-left: 16px;
    width: 100%;
}
.QuantityBtn button{
    /* padding: 11px 17px; */
    background-color: white;
}
.plus{
    border-right:2px solid #000000;
    padding: 10px 13px 10px 13px;
    cursor: pointer;
}
.minus{
    border-left: 2px solid #000000;
    padding: 10px 13px 10px 13px;
    cursor: pointer;
}



.QuantityOfCard p {
    padding: 10px 13px;
    font-size: 18px;
    font-weight: 600;
}


@media (min-width:300px) and (max-width:800px) {
    /* .QuantityOfCard {
        width: 100%;
    } */
    .QuantityBtn{
        margin-left: 0;
        width: 174px;
    }
}