 .shopbybrand2{
    width: 190px;
    height: 130px;
    cursor: pointer;
}
.ContainerOfdBrand button{
  font-size: 20px;
  font-family: 'Nerko One', cursive ;
  font-weight: 600;
  margin: 15px;
  background-color: white;
  color: var(--secondary-color);
  cursor: pointer;
  text-align: center;
}
.slick-next:before, .slick-prev:before {
    color: #000;
}
.arrow-button{
    background: white;
    color: black;
    font-size: 30px;
    cursor: pointer;
}
/* .pats{

} */
.scrolBrand{
    display: flex;
}
/* .slick-track{
    width: 100% !important;
} */
.bestbrand{
    padding: 100px 30px;
    overflow-x: hidden;
    width: 100%;
}
.bestbrandItem{
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.brandsearch{
    justify-content: center !important;

}

.rr{
    position: absolute;
    left: 0;
}

@media (min-width:300px) and (max-width:450px) {
    .ContainerOfdBrand button{
        font-family: "Gill Sans", sans-serif;
    }
    .rr{
        position: relative;
    }
}