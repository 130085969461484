.ContentOfAbout{
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* flex-wrap: wrap; */
    padding: 60px 30px;
}
.Abouttitle{
    font-size: 48px;
}
.AboutParagraphe{
    font-size: 25px;
    text-align: justify;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    width: 90%;
}
.AboutImg{
    width: 570px;
}
b{
    font-size: 25px;
}
@media (min-width:300px) and (max-width:800px) {
    .ContentOfAbout{
        flex-direction: column;
        padding: 10px 10px 0px;
    }
    .AboutImg{
        width: 400px;
    }
    .AboutParagraphe{
        padding: 10px 10px;
    }
    .Abouttitle{
        padding-left: 10px;
    }
}
@media (min-width:801px) and (max-width:1024px) {

    .AboutImg{
        width: 400px;
    }

}