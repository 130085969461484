.ContainerOfAppDonlwoad{
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* flex-wrap: wrap; */
}
.theBigimg img{
    width:660px;
}

.TheDonloawdimg{
    display: flex;
    /* justify-content: space-between; */

}
.TheDonloawdimg img{
    width: 209px;
    cursor: pointer;
}
.contentOfDescriptionOfDonloawd{
    width: 43%;
    padding-left: 157px;
}
.contentOfDescriptionOfDonloawd p{
    font-family: 'Nerko One', cursive;
    font-size: 23px;
    padding: 28px 0px;
    width: 77%;
}
.contentOfDescriptionOfDonloawd h1{
    font-family: 'Nerko One', cursive;
    padding: 10px 0px;
    font-size: 45px;
}

@media (min-width:300px) and (max-width:1000px){
    .ContainerOfAppDonlwoad{
        flex-direction: column;
    }
    .contentOfDescriptionOfDonloawd {
        width: 94%;
        padding-left: 0px;
    }
    .TheDonloawdimg img {
        width: 169px;
    }
    .theBigimg img {
        width: 370px;
        height: 352px;
    }
    .theBigimg{
        padding: 60px 0px;
    }
    .contentOfDescriptionOfDonloawd p {
        width: 100%;
        text-align: justify;
    }
    .contentOfDescriptionOfDonloawd h1 {
        font-size: 38px;
        padding-top: 56px;

    }
}

@media (min-width:1001px) and (max-width:1300px){
    .theBigimg img {
        width: 570px;
        height: 500px;
    }
    .contentOfDescriptionOfDonloawd {
        width: 53%;
        padding-left: 43px;
    }
}
@media (min-width:1301px) and (max-width:1385px) {
    .theBigimg img{
            width: 787px;
    }
}

@media (min-width:300px) and (max-width:450px) {
    .contentOfDescriptionOfDonloawd p{
        font-family: "Gill Sans", sans-serif;

    }
    .contentOfDescriptionOfDonloawd h1{
        font-family: "Gill Sans", sans-serif;
    }
}