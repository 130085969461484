/* .reserve32{
    position: absolute;
    bottom: -2312px !important;
} */
.bestSellerItem{
    cursor: pointer;
    padding-bottom: 51px;

}

.shopbybrand {
    width: 170px;
    height: 140px;
}
.ContainerOfdescription{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 93%;
    padding: 0px;
}
.ContainerOfdescription p{
    font-size: 22px;
    font-weight: 600;
    font-family: 'Nerko One', cursive ;
    padding-top: 23px;
    text-align: center;
}

@media (min-width:300px) and (max-width:500px) {
    .ContainerOfAllSeller{
        display: grid;
        grid-template-columns: repeat(1,1fr);
    }
    .shopbybrand {
        width: 217px;
    }
    .bestSellerItem {
        padding-bottom: 30px;
        padding-top: 42px;
    }
    .ContainerOfdescription p {
        padding-top: 27px;
        padding-bottom: 28px;
        font-size: 25px;
        font-weight: 600;
        color: var(--secondary-color);
        font-family: "Gill Sans", sans-serif;
    }
}


@media (min-width:501px) and (max-width:800px) {
    .shopbybrand {
        width: 217px;
    }
    .bestSellerItem {
        padding-bottom: 51px;
    }
    .ContainerOfdescription p {
        padding-top: 20px;
        padding-bottom: 28px;
    }
}

@media (min-width:801px) and (max-width:1500px) {

    .shopbybrand {
        width: 217px;
    }
    .bestSellerItem {
        padding-bottom: 50px;
    }
    .ContainerOfdescription p {
        padding-top: 10px;
        padding-bottom: 28px;
    }
}