

.allContainerOfheader{
    width: -webkit-fill-available;
    position: absolute;
    z-index: 1000;
    /* height: 800px; */
  }
  
  .banner-slide {
    position: relative;
    height: 800px;
    z-index: 30;
  }
  .theBigHeader{
    overflow-x: hidden;
  }
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 800px;
    object-fit: cover;
  }


.allContainerOfheader2 {
    background-image: url("./../../images/Banner/1820-900 1.png");
    display: flex;
    flex-direction: column;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
}

.offer-line{
    height: 5%;
    background-color: #6becff;
    text-align: center;
    padding: 5px;
}

.close-button{
    position: absolute;
    right: 10px;
    background-color: transparent;
    font-size: 21px;
    font-weight: bold;
}

.header2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 31px;
    /* background-color: white; */
    height: 33%;
    top: 0;
    right: 0;
    left: 0;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* position: fixed;
    z-index: 1000; */
    padding: 0px 30px;
    /* background-color: white; */
    height: 264px;
    top: 0;
    right: 0;
    left: 0;

}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 77%;
}

.iconss {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: end;
    position: relative;
    top: 70px;
    padding: 0px 3px;
}

.iconss2 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: end;
    position: relative;
    top: 30px;
    padding: 0px 3px;
}

.menu {
    display: none;
}

.cart,
.heart,
.user,
.track {
    width: 30px;
    height: 30px;
    color: white;
    margin-right: 48px;
}

.SerachInputHeader {
    background: white;
    padding: 7px;
    padding-left: 20px;
    width: 32%;
    margin-right: 70px;
    border-radius: 15px;
}

.SerachInputHeader::placeholder {
    color: #252525;
    padding-left: 10px;
}

.default-link {
    font-size: 20px;
    font-weight: 700;
    color: white;
    cursor: pointer;
    display: flex;
}

.holidayA {
    padding-top: 7px;
}

.holiday {
    width: 40px;
    position: relative;
    top: -6px;
}

.default-link:hover {
    font-size: 20px;
    font-weight: 700;
    display: flex;
    color: var(--dark-color);
    cursor: pointer;
}

.sss {
    display: flex;
}

.active-link {
    font-size: 20px;
    font-weight: 700;
    color: var(--dark-color);
    cursor: pointer;
    display: flex;
    /* text-shadow: 2px 2px 14px #d8d5d6; */
}

.logo {
    width: 283px;
    height: 158px;
    position: relative;
    top: -35px;
}

.cart:hover,
.heart:hover,
.user:hover,
.track:hover {
    color: #f6a7c6;
}

.login-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1005;
    border-radius: 15px
}

.close-popup {
    position: absolute;
    top: 10px;
    background: white;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
}



.login-popup.show,
.overlay.show {
    display: block;
}

.numberofItems {
    background-color: #f6a7c6;
    color: var(--secondary-color);
    display: block;
    border-radius: 67%;
    padding: 0px 7px;
    position: absolute;
    top: -4px;
    /* left: 0; */
    right:202px;
    font-weight: 600;
    font-size: 14px;
}
.subSubCategories{
    color: black;
}
.allContainerOfheader {
    cursor: url('./../../images/Header/pawprint.png'), auto;
}

.sousCatProduct {
    display: none;
}

.active-link:hover,
.default-link:hover+.sousCatProduct {
    display: block;
}

.cat-products-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.cat-products-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.cat-products-menu li {
    padding: 10px;
    cursor: pointer;
}

.cat-products-menu li:hover {
    background-color: #f5f5f5;
}

.cat-products-link {
    position: relative;
    display: inline-block;
}

.shopByCatsContainer {
    position: relative;
    /* Add any other styles you need for the container */
}

.menuContainer {
    display: flex;
    /* Add any other styles you need for the container */
}


.menuForCats {
    position: absolute;
    top: 105%;
    left: -23px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    box-shadow: 0px 2px 14px rgba(134, 134, 134, 0.795);
    border-radius: 0px;
    padding: 10px;
    width: 179px;
    border-radius: 10px;
    animation: fadeIn 0.3s ease;
    z-index: 10000;
}

.limenu , .limenu2 {
    list-style: none;
    border-bottom: 1px solid #ecebeb;
    /* border: 1px solid #ddd; */
}

.subMenuContainer {
    position: relative;
    border-bottom: 1px solid #ddd;
}

.limenu2:last-child {
    border-bottom: none;
}

.subCategories {
    text-decoration: none;
    color: var(--secondary-color);
    padding: 8px;
    font-weight: 600;
    font-size: 18px;
    display: block;
}
.active-subcategory{
    color: white;
    background-color: var(--dark-color);
}

.subCategories:hover {
    background-color: var(--dark-color); 
}
.subSubCategories ul:hover{
    background-color: var(--dark-color); 

}


.active-subcategory {
    background-color: #ffc0cb;
}
.subSub li{
    position: relative;
    border-bottom: 1px solid #ddd;
}

.subSub li:last-child{
    border-bottom: none;
}
/* .subSubCategoriesContainer {
    position: absolute;
    top: 10px;
    left: 0;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    box-shadow: 0px 2px 14px rgba(134, 134, 134, 0.795);
    border-radius: 0px;
    padding: 10px;
    width: max-content;
    z-index: 1000;
} */
.subSub{
    position: relative;
    left: 109%;
    top: -45px;
    background: white;
    width: max-content;
    border-radius: 6px;
}

.limenu2 {
    list-style: none;
    height: 58px;
}

.subSubCategories {
    text-decoration: none;
    color: #333;
    padding: 10px 8px;
    font-size: 18px;
    display: block;
}

.subSub li:hover {
    background-color: var(--dark-colo); /* Adjust background color on hover */
}
.catsss{
    color: #f6a7c6;
}
/* Animation keyframes for fadeIn effect */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-5px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@media (min-width:300px) and (max-width:1010px) {
    .menu {
        display: block;
        width: 30px;
        color: white;
        height: 30px;
        cursor: pointer;
        position: relative;
        top:-3px;
    }
    .track{
        margin-right: 3px;

    }

    .header2 {
        height: 61%;
    }

    .header {
        position: relative;
        justify-content: space-around;
        padding: 0px;
        top: 10px;
        height: 61%;
    }

    .sss {
        justify-content: center;
    }

    .navbar {
        position: absolute;
        top: -15px;
        left: 0;
        right: 0;
        width: 100%;
        height: 700px;
        z-index: 100;
        text-align: center;
        background: var(--secondary-color);
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        animation-duration: 0.3s;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .holiday {
        width: 40px;
        height: 40px;
        position: relative;
        top: 13px;
    }

    /* .iconss{
        width: 40%;
        justify-content: space-around;
    } */
    .navbar.open {
        /* display: block; */
        animation-name: slideIn;
        animation-fill-mode: forwards;
    }

    .navbar.close {
        display: none;
        animation-name: slideOut;
        animation-fill-mode: forwards;
    }

    .navbar a {
        color: white;
        display: block;
        line-height: 1.8;
        margin: 20px;
        height: 58px;
        cursor: pointer;
        /* transition: 0.4s; */

    }

    /* .navbar a:hover {
        transform: scale(1.1);
    } */

    .SerachInputHeader {
        display: none;
    }
    .menuForCats{
        display: none;
    }

    .active-link {
        color: var(--dark-color) !important;
    }

    .allContainerOfheader {
        height: 536px;
    }

    .logo {
        width: 346px;
        height: 195px;
        position: relative;
        top: 23px;
    }

    .iconss2 {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-around;
        position: relative;
        top: 30px;
    }

    .iconss {
        width: 100%;
        justify-content: space-around;
    }

    .header {
        position: relative;
        justify-content: space-around;
        padding: 0px;
        top: 90px;
        height: 22%;
    }

    /* .logo {} */


    .cart,
    .heart,
    .user {
        width: 30px;
        height: 30px;
        color: white;
        margin-right: 10px;
    }

    .numberofItems {
        right: 39%;
    }

    @keyframes slideIn {
        from {
            transform: translateY(-100%);
        }

        to {
            transform: translateY(0);
        }
    }

    @keyframes slideOut {
        from {
            transform: translateY(0);
        }

        to {
            transform: translateY(-100%);
        }
    }

}

@media (min-width:300px) and (max-width:500px) {
    .login-popup {
        width: 96%;
    }


}
@media (min-width:300px) and (max-width:450px) {
.imgDIV {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    }
    .logo{
        top: 61px ;
    }
    .iconss{
        top: 68px;
    }
    .offer-line {
        height: 9%;
        background-color: #6becff;
        text-align: center;
        padding: 12px;
    }
}

