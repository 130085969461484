.ContainerOfTips{
    margin-top: 161px;
}
.ContentOfTips{
    background: var(--secondary-color);
    margin-right: 50px;
    margin-left: 50px;
    padding: 50px 50px;
    border-radius: 10px;
}
.ContentOfTips h1{
    font-family: 'Nerko One', cursive;
    color: white;
    padding-bottom: 10px;
}
.ContentOfTips p{
    font-family: 'Nerko One', cursive;
    font-size: 23px;
    color: #e1e1e1;
    /* opacity: .7; */
    padding-bottom: 10px;
}
.tips1{
    position: absolute;
    left: -30px;
    cursor: url('./../../images/Header/pawprint.png'), auto;
}
.tips11{
    position: relative;
    top: -54px;
    width: 395px;
    height: 376px;
    left: 6px;
    cursor: url('./../../images/Header/pets.png'), auto;
}


.tips22{
    position: relative;
    top: -50px;
    width: 387px;
    height: 382px;
    cursor: url('./../../images/Header/paw.png'), auto;
} 
.tips2{
    position: absolute;
    right: -20px;
}
.catoDogo{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (min-width:501px) and (max-width:800px) {
    .tips11{
    position: relative;
    top: -36px;
    width: 262px;
    height: 274px;
    left: 20px;
    }
    .tips22{
        width: 262px;
        height: 254px;
        top: -33px;
    }
    .tips2 {
        position: absolute;
        right: 5px;
    }

}

@media (min-width:300px) and (max-width:500px){
    .tips1{
        display: none;
    }
    .tips2{
        display: none;
    } 
    .ContentOfTips h1{
        font-family: "Gill Sans", sans-serif;

    }
    .ContentOfTips {
        margin-right: 12px;
        margin-left: 12px;
        padding: 33px 18px;
    }
    .ContentOfTips p{
        font-family: "Gill Sans", sans-serif;
    }
    .ContainerOfTips {
        margin-top: 71px;
    }

}

