.craeteAccount2{
    display: flex;
    flex-direction: column;
    padding: 39px;
    /* width: 39%; */
}
.signin{
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    color: var(--secondary-color);
}
.btnLogin{
    padding: 10px 19px;
    font-size: 20px;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
    width: -webkit-fill-available;
    color: #003440;
    background: #f6a7c6;
}
.signSpan{
    display: inline-block;
    color: var(--dark-color);
    font-weight: 600;
    font-size: 20px;
}
.pLogin{
    width: 100%;
    text-align: center;
    padding-top: 15px;
    font-size: 20px;
}
.formInput{
    width: 59%;
}
.thedivwithopacity{
    background-color: rgba(0, 0, 0, 0.61);
    height: 100vh;
}
.therealPop{
    position: absolute;
    z-index: 10000;
}
.errorMessageForLogin{
    text-align: center;
    color: white;
    font-size: 18px;
    font-weight: 600;
    background: #ff0000b5;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 23px;
}
.google_button{
    width: 349px !important;
    font-size: 16px;
    /* font-weight: 600; */
    margin: 6px 0px 0px;
    display: flex;
    border-radius: 5px !important;
    justify-content: space-evenly;
    cursor: pointer;
    padding: 10px 19px;
    color: #ffffff;
    background: #f6a7c6 !important;
  }
.google_button img{
    width: 23px
} 
.nsm7Bb-HzV7m-LgbsSe {
   
    border: none !important;
    /* color: #3c4043; */
    padding: 0 0px !important;

}
.nsm7Bb-HzV7m-LgbsSe {
    border: 0px solid !important;
}
.sss{
    display: block;
}

@media (min-width:300px) and (max-width:450px){
    .btnLogin{
        width: 351px;
    }
    .craeteAccount2{
        align-items: center;
        justify-content: center;
    }
    .formInput {
        width: 89%;
    }
}