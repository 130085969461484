.ALLMore{
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    padding: 50px 0px;
}
.MoreDetails{
    display: flex;
    justify-content: space-between;
    padding: 10px 0px 21px;
}
.MoreDetails h1{
    font-size: 25px;

}
.MoreLeft{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 77px 0px 0px;
}
.imgMore{
    width: 350px;
    height: 400px;
    border-radius: 10px;
    /* filter: drop-shadow(6px 7px 8px #a09f9fd8); */
}
.divContientTheOtherColorOfProduct{
    padding: 45px 0px;
    width: 100%;
    /* display: flex; */
}
.MoreTitleName{
    display: flex;
    flex-direction: column;
    /* align-items: center */
}
.MoreTitleName p{
    font-family: 'Nerko One', cursive;
    font-size: 22px;
    padding-top: 10px;
    
}
.MoreTitleName h2{
    font-family: 'Nerko One', cursive ;
    font-size: 44px;
    padding-top: 10px;
    width: 100%;
    /* padding-bottom: 20px; */
}
.MoreIngredient{
    display: flex;
    justify-content: space-between;
}
.MoreIngredient h1{
    font-size: 25px;
}
.minusPlus{
    cursor: pointer;
}
.minusPluss{
    font-size: 20px;
}
.widthOfMore{
    width: 40%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 83px 20px 20px;
}
.Description p {
    padding-bottom: 20px;
}
.Ingredients p{
    padding-top: 20px;
}
.BuyNowPlusQuantity{
    display: flex;
    /* flex-direction: column; */
    /* justify-content: center;
    align-items: center; */
    padding: 37px 0px;
}
/* .ByBtn{
    padding: 8px 24px;
    font-size: 18px;
    position: relative;
    border-radius: 10px;
    font-weight: 600;
    background: white;
    color: var(--secondary-color);
    border: 3px solid var(--secondary-color);
    cursor: pointer;
} */
.allsliderOfMore{
  padding: 87px 20px;
}
/* .ContainerOfDetails{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 0px;

} */
.MoreScroll{
    width: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    /* padding-bottom: 38px; */
}
.ContainerOfDetails p{
    font-size: 20px;
    text-align: center;
    padding: 10px 0px;
    font-weight: 500;
}
.ContainerOfDetails h2{
    text-align: center;
}
.More2{
    width: 197px;
    background: #e1e1e1 !important;
    border-radius: 10px;
    height: 239px;
}
.allsliderOfMore h1{
    font-size: 30px;
    font-family: 'Nerko One', cursive;
    padding: 47px 0px 47px 110px;
}
.priceInMoreDetails{
    padding-top: 10px;
}
.priceInMoreDetails h4{
    padding-bottom: 5px;
    /* text-align: center; */
}
.priceInSpan{
    display: inline-block;
    font-weight: bold;
    font-size: 17px;
    color: #eb5993;
}
/* .slick-slider{
    display: flex;
} */
.ContainerOfDetails{
    padding: 2px 39px;
}
/* .slick-dots {
    position: absolute;
    bottom: -71px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
} */
.theproductmeni img{
    width: 80px;
    height: 80px;
    margin-top: 18px;
    margin-left: 16px;
    border-radius: 10px;
    cursor: pointer;

}
.containerofproductRealeteds{
    /* display: flex;
    justify-content: space-around;*/
    /* display: flex; */
    width: 321px; 
    padding: 20px;
    padding-top: 30px;

}
.selected img{
    transform: scale(1.3);
    filter: drop-shadow(6px 7px 8px #a09f9fd8);
    /* border: 2px solid red; */
}
.btnForAllmore{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.pSize{
    padding-left: 60px;
    font-size: 20px;
    font-weight: 700;
    color: var(--dark-color);
    font-family: 'Nerko One', cursive ;
}

.theproductmeni{
    height: 155px;
}
@keyframes cuteButtonAnimation {
    0% {
        transform: scale(1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    50% {
        transform: scale(1.05);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
}
.sfs{
    cursor: pointer;
}

.ByBtn{
    padding: 12px 24px;
    font-size: 18px;
    margin-top: 10px;
    border-radius: 10px;
    font-weight: 600;
    background: var(--dark-color);
    color: var(--secondary-color);
    background: linear-gradient(90deg, #ed6fa3c7, #f5a6c596);;
    border: none;
    cursor: pointer;
    width: 200px;
    /* animation: cuteButtonAnimation 1s infinite; */
}

.addBtn{
    padding: 12px 24px;
    font-size: 18px;
    margin-top: 10px;
    border-radius: 10px;
    font-weight: 600;
    background: var(--dark-color);
    color: var(--secondary-color);
    background: linear-gradient(90deg, #ed6fa3c7, #f5a6c596);;
    border: none;
    cursor: pointer;
    width: 200px;
}
.SizesMore{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.sizeContrainer{
    padding-right: 5px;
}

.sizeContrainer button{
    border: 2px solid #000000;
    padding: 5px;
    width: 44px;
    height: 44px;
    font-size: 16px;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    background: #ffffff;
}

.addBtn:hover{
    animation: cuteButtonAnimation 1s infinite;
}

.ByBtn:hover {
    animation: cuteButtonAnimation 1s infinite;
}
.sizeBtn{
    cursor: pointer;
}

.ContainerOfdescriptionAndPriceInMoreDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:85%;
    cursor: pointer;
    padding: 0px
}

.ContainerOfdescriptionAndPriceInMoreDetails h2 {
    padding-bottom: 10px;
    font-weight: 300;
    padding-top: 16px;
    font-size: 21px;
    text-align: center;
    font-family: 'Nerko One', cursive ;
    color: var(--secondary-color);
}

.ContainerOfdescriptionAndPriceInMoreDetails p {
    font-weight: 600;
    font-size: 20px;
    font-family: 'Nerko One', cursive ;
    color: var(--secondary-color);
}

@media (min-width:300px) and (max-width:800px) {
    .ALLMore{
        flex-direction: column;
    }
    .Description{
        /* width: min-content; */
        width: 100%;
    }
    .BuyNowPlusQuantity{
        flex-direction: column;
    }
    .MoreIngredient{
        width: 100%;
        /* width: max-content; */
    }
    .minusPlus {
        cursor: pointer;
        padding: 9px 0px;
    }
    .allsliderOfMore h1{
        padding: 0;
    }
    .btnForAllmore{
        align-items: flex-start;
        flex-direction: column;
    }
    .widthOfMore {
        width: 100%;
    }
    .imgMore {
        width: 285px;
    }
    .SizesMore{
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 39px;
    }
    .pSize{
        padding-left: 0px;
        padding-top: 30px;
    }
    .widthOfMore {
        padding: 0px 20px 20px;
    }
    .MoreLeft {
       padding: 0;
    }
    .MoreTitleName h2 {
        font-size: 26px;
    }

}

@media (min-width:800px) and (max-width:1100px) {
    .ByBtn{
        margin-right: 10px;
    }
}

@media (min-width:300px) and (max-width:450px) {
    .MoreTitleName p , .MoreTitleName h2 , .pSize , .allsliderOfMore h1{
        font-family: "Gill Sans", sans-serif;
    }
}