.Cartcontainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0px;
}
.Cartcontainer h1{
    font-size: 41px;
    font-family: 'Nerko One', cursive;
    padding-bottom: 33px;
    color: var(--secondary-color);
    text-align: justify;
    width: 64%;
}
.ContainerForOneItem{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 76%;
    padding: 16px 0px;
    border-bottom: 1px solid #e1e1e1;
}
.ContainerForOneIte{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid #e1e1e1;
    width: 100%;
}
.cartItem{
    width: 100px;
    /* filter: drop-shadow(-2px 6px 3px #6d6767); */
}

.contentOfcart{
    background: #e1e1e1;
    padding: 11px 28px;
    border-radius: 10px;
    width: 255px;
}
.contentOfcart h3{
    font-size: 20px;
    font-family: 'Nerko One', cursive ;
    padding-bottom: 10px;
}
/* .QuantityOfCard{
    width: 16%;
} */
.btnPlusPriceCart{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 23px;
}

@keyframes cuteButtonAnimation {
    0% {
        transform: scale(1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    50% {
        transform: scale(1.05);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
}

.cartbtn {
    padding: 12px 24px;
    font-size: 18px;
    margin-top: 10px;
    border-radius: 10px;
    font-weight: 600;
    background: var(--dark-color);
    color: var(--secondary-color);
    background: linear-gradient(90deg, #ed6fa3c7, #f5a6c596);;
    border: none;
    cursor: pointer;
    animation: cuteButtonAnimation 1s infinite;
}

.cartbtn:hover {
    animation: none; 
    transform: scale(1.1);
    color: white;
    background: var(--secondary-color); 
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
.totalcartcheckout{
    display: flex;
    flex-direction: column;
}
.trash{
    cursor: pointer;
    transition: 0.3s;
}
.trash:hover{
    animation: bounce 0.5s ease infinite;
}

.custom-success-icon-class{
    width: 100%;
    height: 100%;
}



@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0); /* Bounce at the top and bottom */
    }
    40% {
      transform: translateY(-15px); /* Bounce upward */
    }
    60% {
      transform: translateY(-7px); /* Bounce downward */
    }
  }

.TotalPrice1{
    padding: 14px 0px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Nerko One', cursive ;
    color: var(--secondary-color);
}
.TotalPrice11{
    padding-right: 9px;
    font-weight: 600;
    font-size: 20px;
}
.custom-toast{
    background-color: white;
    color: black;

}
.custom-toast1{
    background-color: white;
    color: black;
}
.ContainerForOneItem1{
    width: 100% !important;
    /* display: flex; */
    /* padding: 20px; */
    justify-content: space-around;
    align-items: center;
}

@media (min-width:300px) and (max-width:900px) {
    .ContainerForOneItem{
        flex-direction: column;
        border: 2px solid rgb(0 0 0 / 53%);
        justify-content: center;
        padding: 15px;
        margin-bottom: 15px;
        border-radius: 15px;
        box-shadow: 2px 4px 5px rgb(0 0 0 / 50%);
    }
    .ContainerForOneItem1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px;
        border-radius: 15px;
        box-shadow: 2px 4px 5px rgb(0 0 0 / 50%);
        border: 2px solid rgb(0 0 0 / 53%);
        margin: 20px 0px;
    }
    .ContainerForOneIte{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .contentOfcart{
       margin: 20px 0px;
    }
    
    /* .QuantityOfCard {
        width: 100%;
    } */
    .quantity-buttons {
        display: flex;
        /* flex-direction: row; */
        align-items: center;
        width: 100%;
        justify-content: center;
    }
}

@media (min-width:300px) and (max-width:450px){
    .Cartcontainer h1{
        font-family: "Gill Sans", sans-serif;

    }
    .contentOfcart h3{
        font-family: "Gill Sans", sans-serif;

    }
    .TotalPrice1{
        font-family: "Gill Sans", sans-serif;

    }
    .TotalPrice11{
        padding: 20px;
    }
}