.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0px;
    /* height: 100vh; */
  }
  
  .spinner {
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-left: 5px solid var(--dark-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  