.allContainerOfSearchBar{
    display: flex;
    justify-content: end;
    width: 84%;
    margin-bottom: 44px;
}
.holidaySearchStyle{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 44px;
}

.search-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 49%;
}

.search-input {
    padding: 8px;
    font-size: 16px;
    border: 2px solid #e1e1e1;
    border-radius: 10px;
    background: #e1e1e1;
    width: -webkit-fill-available;
}

.search-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #6d6d6d;
}
#mySelect{
    background: #e1e1e1;
    border: 2px solid #e1e1e1;
    border-radius: 10px;
    padding: 8px 21px;
    font-size: 18px;
    margin-left: 10px;
}


@media (min-width:300px) and (max-width:600px) {
    .allContainerOfSearchBar {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 44px;
    }
}

@media (min-width:601px) and (max-width:1100px){
    .allContainerOfSearchBar {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 44px;
        margin-top: 20px;
    }
}


@media (min-width:1101px) and (max-width:1200px){
    .allContainerOfSearchBar {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 44px;
    }
}
