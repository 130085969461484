
.custom-select {
    display: flex;
    flex-direction: column;
    width: 15%;

  }
  
  .category {
    position: relative;
    width: 200px;
    margin-bottom: 8px;
  }
  
  .category-header {
    cursor: pointer;
    font-family: 'Nerko One', cursive ;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    width: 350px;
    font-weight: 600;
  }
  
  .options-container {
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    display: contents;
    background-color: #fff;
    margin-top: 8px;
  }
  
  .option {
    padding: 10px;
    cursor: pointer;
    font-family: 'Nerko One', cursive ;
    border-bottom: 1px solid #ccc;
    width: 342px;
  }
  
  .option:last-child {
    border-bottom: none;
  }
  
  .caret-icon {
    margin-left: 5px;
  }
  .pats{
    position: absolute;
    left: 0;
  }
  @media (min-width:300px) and (max-width:500px) {
    .custom-select {
      padding-bottom: 62px;
      display: flex;
      flex-direction: column;
      width: 57%;
      justify-content: center;
      align-items: center;
  }
  .pats{
    display: none;
  }
  }
  @media (min-width:501px) and (max-width:1100px) {
    .custom-select {
      padding-bottom: 62px;
      display: flex;
      flex-direction: column;
      width: 77%;
      justify-content: center;
      align-items: center;
  }
  .pats{
    display: none;
  }
  }

  @media (min-width:1101px) and (max-width:1350px) {
    .category-header {
      width: 223px;
  }
  .option{
    width: 210px;
  }
  }

  @media (min-width:300px) and (max-width:450px) {
    .category-header {
      font-family: "Gill Sans", sans-serif;

  }
  .option{
    font-family: "Gill Sans", sans-serif;

  }
  }