.ContainerOffooter{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--secondary-color);
    padding: 10px;
    width: 100%;
    color: white;
    padding-bottom: 87px;
}
.ContainerofAbout{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 35%;
    padding: 42px 30px;
}
.ContainerofAbout h2{
    padding: 20px 0px;
}
.ContainerofAbout p{
    font-size: 15px;
    padding-right: 83px;
}
.ContainerOfServices{
    display: flex;
    flex-direction: column;
    position: relative;
    top: -63px;
    width: 15%;
}
.ContainerOfShopFooter {
    display: flex;
    flex-direction: column;
    position: relative;
    top:45px;
    width: 15%;
}
.ContainerOfShopFooter a:hover{
    color: var(--dark-color);
}
.ContainerOfShopFooter h2 {
    padding-bottom: 21px;
}
.ContainerOfServices h2{
    padding-bottom:4px
}
.detailUl{
    padding-top: 20px;
}
.ContainerOfShopFooter li, .ContainerOfServices li{
    padding-bottom: 12px;
    font-size: 18px;
}
.ContainerOfShopFooter img{
    width: 16px;
    margin-right: 12px;
}
.continerOfcontactFooter{
    display: flex;
    flex-direction: column;
    width: 25%;
    position: relative;
    top:80px;
}
.imgDonwload{
    padding-top: 10px;
    padding-bottom: 20px;
}
.imgDonwload img{
    width: 150px;
    cursor: pointer;
}
.InputEmail{
    display: flex;
    
    /* justify-content: space-between; */
}
.InputEmail input{
    border-radius: 10px;
    width:233px;
    padding: 10px;
}
.InputEmail button{
    Width:112px;
    border-radius: 10px;
    margin-left: 10px;
    background-color: var(--dark-color);
    pad: 10px;
    font-weight: 600;
    color: var(--secondary-color);
}

.email::placeholder{
    color: #a3a3a3;
    font-weight: 700;
}
.continerOfcontactFooter input{
    width:342px;
    border-radius: 10px;
    padding: 10px;
    margin-top: 33px;
}

.continerOfcontactFooter textarea{
    width: 342px;
    height: 100px;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
}
.continerOfcontactFooter button{
    width: 78px;
    height: 41px;
    border-radius: 10px;
    margin-top: 10px;
    cursor: pointer;
    background-color: var(--dark-color);
    color:var(--secondary-color);
    font-weight: 600;
}

.appdonFooter{
    padding-top: 18px;
}
.socialMedia{
    display: flex;
    padding-top: 19px;
}
.icon{
    color: var(--secondary-color);
    background: var(--dark-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px
}
.icons{
    width: 20px;
    height: 20px;
    text-align: center;

}
.Toastify__toast--error{
    background-color: #ffffff !important;
    color: #000000 !important;
}
.swal-button {
    background-color: #08343f  !important;
    /* border: 1px solid #a5dc86; */
}
.logo2{
    width: 288px;
    height: 177px;
    position: relative;
    right: 52px;
}
.b{
    font-size: 28px;
}
.bannenrHidden{
    display: none;
}
@media (min-width:300px) and (max-width:990px) {
    .ContainerOffooter{
        flex-direction: column;
    }
    .ContainerofAbout{
        width: 100%;
    }
    .ContainerOfShopFooter{
        top: 0px;
        width: 87%;
    }
    .ContainerOfServices {
        top: 0px;
        width: 87%;
    }
    .continerOfcontactFooter{
        width:87%;
    }
    .imgDonwload{
        padding-bottom: 40px;
    }
    .continerOfcontactFooter textarea ,.continerOfcontactFooter input{
        width: 271px;
    }
    .logo2{
        width: 325px;
        height: 194px;
    }
    .imgDonwload img {
        width: 144px;
    }
}
@media (min-width:991px) and (max-width:1273px) {
    .continerOfcontactFooter{
        width:35%;
    }
}