.NoProductFound{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 60px 0px;
}

.NoProductFound p{
    color: var(--secondary-color);
    font-size: 40px;
    font-weight: 600;
    text-align: start;
}
.NoProductFound img{
    width: 100px;
    height: 100px;
}
.Holidays{
    width: 100%;
}

@media (min-width:300px) and (max-width:450px) {
    .NoProductFound p {
        font-size: 25px;
    }
}