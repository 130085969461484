.page-container {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 107px;
  }
  
  .prev,
  .next,
  .page-number {
    cursor: pointer;
    font-size: 21px;
    font-weight: 600;
    padding: 8px;
    margin: 0 5px;
    color: var(--secondary-color)
  }
  
  .prev:hover,
  .next:hover,
  .page-number:hover {
    background-color: #eee;
  }
  
  .page-number.active {
    background-color: #f6a7c6;
    color: #fff;
    border-radius: 10px;
    padding: 4px 20px;
  }
  .allNumber{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 47px 0px 44px
  }
.Result30{
    color: var(--secondary-color);
    font-size: 18px;
    padding-right: 12px
  }

@media (min-width:300px) and (max-width:900px) {
  .allNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 32px;
    flex-direction: column;
    padding-top: 30px;
    padding-right: 0px;
}
.page-container{
  justify-content: center;
  padding-right: 0px;
}
.Result30{
  padding-top: 20px;
  font-weight: 600;
}
}
