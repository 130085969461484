.banner h1 {
    font-family: 'Nerko One', cursive;
    font-size: 69px;
    color: white;
    width: 60%;
}

.banner {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    padding: 104px 62px;
    position: absolute;
    z-index: 50;
    top: 249px;
    width: 100%
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.Shop {
    padding: 12px 30px;
    font-size: 23px;
    position: relative;
    top: 34px;
    border-radius: 10px;
    font-weight: bold;
    /* color: #003440;
    background: linear-gradient(90deg, #ffff, #ff82b3); */
    /* border: 2px solid #003440; */
    color: #fbffff;
    background: linear-gradient(202deg, #003440, #003440);
    background-size: 200% 200%;
    transition: color 0.4s, transform 0.4s;
    cursor: pointer;
    z-index: 100;
    animation: gradientAnimation 3s infinite;
}

.Shop:hover {
    color: white;
    transform: scale(1.1);
    animation: none;
    /* Disable the animation on hover */
}

@media (min-width:300px) and (max-width:1000px) {


    .banner h1 {
        width: 100%;
        font-size: 47px;
    }

    .banner {
        width: 100%;
        top: 250px;
    }

}


@media (min-width:300px) and (max-width:450px) {
    .banner h1 {
        font-family: "Gill Sans", sans-serif;
    }

    .banner{
        width: 100%;
        top: 352px;
    }

    /* .Shop {
        border: 2px solid #003440; 
        color: var(--secondary-color);
        background: linear-gradient(202deg, #ffffff, #ed6fa3)
    } */
}