.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  /* .loader {
    border: 4px solid var(--dark-color);
    border-top: 2px solid #fff; 
    border-radius: 50%;
    width: 250px;
    height: 250px; 
    animation: spin 1s linear infinite;
    position: relative; 
  }
   */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .logoLoading{
    width: 300px; /* Adjust the size of your logo as needed */
    height: 150px; /* Adjust the size of your logo as needed */
    /* position: absolute */
  }

  .loader{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* .loading-dots {
    display: flex;
    margin-right: 10px; 
    padding-top: 70px;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--dark-color);
    margin-right: 5px; 
    animation: dot-animation 1s infinite;
  } */
  .logoWithdots{
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    align-items: center;
  }
  
  @keyframes dot-animation {
    0%, 20% { transform: scale(1); }
    50% { transform: scale(1.5); }
    100% { transform: scale(1); }
  }
  

  @media (min-width:300px) and (max-width:500px) {
    .loader img{
      width: 300px;
    }
    /* .logoLoading{
      width: 250px;
      height: 200px;

    } */
  }